import React, { useState, useContext, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion, AnimatePresence } from 'framer-motion'
import { useWindowScroll, useScroll, useMount } from 'react-use'
import { use100vh } from 'react-div-100vh'
import { Link as ScrollLink, Element as ScrollElement } from 'react-scroll'

import { Layout, Header } from '../components'
import { container, padding, bgImage, type, hoverState } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'

import { data } from '../data/home'
import { ThemeContext } from '../context/ThemeContext'
import { IsMobileContext } from '../context/IsMobileContext'
import { deviceDetect } from 'react-device-detect'

import { maxWidth } from '../styles/global'
import { headerHeight, phoneHeaderHeight } from '../components/Header/Header'

const Index = (props) => {
    const theme = useContext(ThemeContext)
    const { isMobile } = useContext(IsMobileContext)
    const screenHeight = use100vh()
    const { x, y } = useWindowScroll()
    const scrollRef = useRef(null)
    const [colouredHeader, setColouredHeader] = useState(false)
    const isPhone = useBreakpoint('phone')

    useEffect(() => {
        if (!scrollRef || !scrollRef.current) return
        const scrollTop = scrollRef.current.getBoundingClientRect().top
        if (scrollTop - headerHeight <= 0) {
            setColouredHeader(true)
        } else {
            setColouredHeader(false)
        }
    }, [y])

    const renderScrollLink = (section, colour, flip) => {
        return (
            <Icon
                as={ScrollLink}
                to={section}
                offset={0}
                smooth={true}
                duration={500}
            >
                <motion.svg
                    className="down-arrow"
                    width="36px"
                    height="23px"
                    viewBox="0 0 36 23"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        transform: flip && 'rotate(-180deg)',
                    }}
                >
                    <motion.g
                        initial={{ fill: colour || theme.colour }}
                        animate={{ fill: colour || theme.colour }}
                        transition={{ duration: theme.duration }}
                        stroke="none"
                        stroke-width="1"
                        fillRule="evenodd"
                    >
                        <path
                            d="M7.1581,11.0549 C7.1581,9.0529 8.7581,7.3699 10.7591,7.3699 C12.7161,7.3699 14.3161,9.0529 14.3161,11.0549 C14.3161,13.0569 12.7161,14.7399 10.7591,14.7399 C8.7581,14.7399 7.1581,13.0569 7.1581,11.0549"
                            id="Fill-1"
                        ></path>
                        <path
                            d="M21.6966,11.0549 C21.6966,9.0529 23.2966,7.3699 25.2976,7.3699 C27.2546,7.3699 28.8546,9.0529 28.8546,11.0549 C28.8546,13.0569 27.2546,14.7399 25.2976,14.7399 C23.2966,14.7399 21.6966,13.0569 21.6966,11.0549"
                            id="Fill-3"
                        ></path>
                        <path
                            d="M0,3.6849 C0,1.6829 1.6,-0.0001 3.601,-0.0001 C5.558,-0.0001 7.158,1.6829 7.158,3.6849 C7.158,5.6869 5.558,7.3699 3.601,7.3699 C1.6,7.3699 0,5.6869 0,3.6849"
                            id="Fill-5"
                        ></path>
                        <path
                            d="M28.8547,3.6849 C28.8547,1.6829 30.4547,-0.0001 32.4557,-0.0001 C34.4127,-0.0001 36.0127,1.6829 36.0127,3.6849 C36.0127,5.6869 34.4127,7.3699 32.4557,7.3699 C30.4547,7.3699 28.8547,5.6869 28.8547,3.6849"
                            id="Fill-8"
                        ></path>
                        <path
                            d="M14.3162,18.5156 C14.3162,16.4226 15.9612,14.7396 18.0062,14.7396 C20.0512,14.7396 21.6962,16.4226 21.6962,18.5156 C21.6962,20.6086 20.0512,22.2916 18.0062,22.2916 C15.9612,22.2916 14.3162,20.6086 14.3162,18.5156"
                            id="Fill-11"
                        ></path>
                    </motion.g>
                </motion.svg>
            </Icon>
        )
    }

    const renderIntro = () => {
        return (
            <Intro
                initial={{ backgroundColor: theme.colour }}
                animate={{ backgroundColor: theme.colour }}
                transition={{ duration: theme.duration }}
            >
                <Container
                    as={ScrollElement}
                    name={'intro-section'}
                    style={{
                        minHeight: screenHeight,
                    }}
                >
                    <Info>
                        <MainDescription
                            dangerouslySetInnerHTML={{
                                __html: data?.intro?.heading,
                            }}
                        />
                    </Info>
                    {renderScrollLink('about-section', 'black', false)}
                </Container>
            </Intro>
        )
    }

    const renderAbout = () => {
        return (
            <About ref={scrollRef}>
                <Container
                    as={ScrollElement}
                    name={'about-section'}
                    style={{
                        minHeight: screenHeight,
                    }}
                >
                    <Info>
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: data?.about?.description,
                            }}
                        />
                        <CTA>
                            <p>
                                We’d love to&nbsp;
                                <motion.a
                                    key={'cta'}
                                    href="mailto:info@pavilion.partners"
                                    initial={{ color: theme.colour }}
                                    animate={{ color: theme.colour }}
                                    transition={{ duration: theme.duration }}
                                >
                                    hear from you.
                                </motion.a>
                            </p>
                        </CTA>
                    </Info>
                </Container>
            </About>
        )
    }

    const renderPartnerImages = () => {
        const items = data?.partners?.items.map((item, i) => {
            return (
                <PartnerImage
                    key={i}
                    initial={'hidden'}
                    animate={
                        (!theme.activeIndexOverride && theme.activeIndex === i + 1) ||
                        theme.activeIndexOverride === i + 1
                            ? 'visible'
                            : 'hidden'
                    }
                    variants={fadeVariants}
                >
                    <BGImage image={item.image} />
                </PartnerImage>
            )
        })

        return (
            <PartnerImages>
                {items}
                <OverlayColour
                    initial={{ backgroundColor: theme.colour }}
                    animate={{ backgroundColor: theme.colour }}
                    transition={{ duration: theme.duration }}
                />
            </PartnerImages>
        )
    }

    const renderPartnerItems = () => {
        const items = data?.partners?.items?.map((item, i) => {
            return (
                <Item
                    key={i}
                    href={item.website}
                    target="_blank"
                    onMouseEnter={() => {
                        if (theme.activeIndexOverride !== i + 1 && !isMobile) {
                            theme.setActiveIndexOverride(i + 1)
                        }
                    }}
                >
                    <BGOverlay
                        key={`bg-${i}`}
                        initial={'hidden'}
                        animate={
                            (!theme.activeIndexOverride && theme.activeIndex === i + 1) ||
                            theme.activeIndexOverride === i + 1 ||
                            isMobile
                                ? 'visible'
                                : 'hidden'
                        }
                        variants={fadeVariants}
                    />

                    <Content>
                        <PartnerLogoWrap
                            intial={{ opacity: 0.5 }}
                            animate={{
                                opacity:
                                    (!theme.activeIndexOverride && theme.activeIndex === i + 1) ||
                                    theme.activeIndexOverride === i + 1 ||
                                    isMobile
                                        ? 1
                                        : 0.5,
                            }}
                            duration={{ duration: theme.duration }}
                        >
                            <PartnerLogo
                                src={item?.logo}
                                mobileWidth={item?.mobileLogoWidth}
                            />
                        </PartnerLogoWrap>
                        <Tagline
                            key={`tag-${i}`}
                            initial={'hidden'}
                            animate={
                                (!theme.activeIndexOverride && theme.activeIndex === i + 1) ||
                                theme.activeIndexOverride === i + 1 ||
                                isMobile
                                    ? 'visible'
                                    : 'hidden'
                            }
                            variants={fadeVariants}
                            dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}
                            style={{
                                color: item.colour,
                            }}
                        />
                    </Content>
                </Item>
            )
        })

        return (
            <PartnerItems
                onMouseLeave={() => theme.setActiveIndexOverride(false)}
                style={{
                    minHeight: screenHeight,
                }}
            >
                {items}
            </PartnerItems>
        )
    }

    const renderPartners = () => {
        return (
            <Partners as={ScrollElement} name={'partners-section'}>
                {renderPartnerImages()}
                {renderPartnerItems()}
            </Partners>
        )
    }

    const renderFooter = () => {
        return (
            <Footer>
                <Header fixed={false} includeCTA={false} />
                <Container>
                    <Info>
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: data?.footer?.contactInfo,
                            }}
                        />
                        <SmallText
                            dangerouslySetInnerHTML={{
                                __html: data?.footer?.copyright,
                            }}
                        />
                    </Info>
                    {renderScrollLink('intro-section', false, true)}
                </Container>
            </Footer>
        )
    }

    const fadeVariants = {
        initial: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0,
        },
        transition: {
            duration: theme.duration,
        },
    }

    const headerVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
        },
        exit: {
            opacity: 0,
        },
        transition: {
            duration: 0.35,
        },
    }

    return (
        <Layout
            //meta={data && data.seo}
        >
            <Wrapper>
                {/* black header */}
                <AnimatePresence>
                    {!colouredHeader && (
                        <motion.div {...headerVariants}>
                            <Header
                                fixed={true}
                                includeCTA={true}
                                logoColour={'#000000'}
                                ctaColour={'#000000'}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* coloured header */}
                <AnimatePresence>
                    {colouredHeader && (
                        <motion.div {...headerVariants}>
                            <Header
                                fixed={true}
                                includeCTA={true}
                                ctaColour={'#ffffff'}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>

                {renderIntro()}
                {renderAbout()}
                {/* {renderPartners()} */}
                {renderFooter()}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Info = styled.div``
const Description = styled.div``
const MainDescription = styled.h1``
const CTA = styled.div``
const Items = styled.div``
const Item = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    /* transition: opacity 0.45s ease; */
`

const Icon = styled.div``

// Layout

const Wrapper = styled.div``

const Container = styled.div`
    ${container}
    ${padding}
`

const sectionContainer = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: ${headerHeight}px;

    ${media.phone`
        padding-top: ${phoneHeaderHeight}px;
    `}

    ${Info} {
        margin: auto 0;
        padding: 48px 0;
    }

    ${Icon} {
        display: flex;
        margin-bottom: 84px;
        cursor: pointer;
        ${hoverState}

        ${media.phone`
            margin-bottom: 32px;
        `}

        svg {
            width: 36px;
            height: auto;

            ${media.phone`
                width: 32px;
            `}
        }
    }
`

// Intro

const Intro = styled(motion.div)`
    ${Container} {
        ${sectionContainer}
    }

    ${MainDescription} {
        ${type.heading1};
        max-width: 950px;
    }
`

// About

const About = styled.div`
    background-color: black;

    ${Container} {
        ${sectionContainer}
        
        ${Info} {
            ${media.phone`
                padding-top: 120px;
            `}
        }
    }

    ${Description}, ${CTA} {
        max-width: 820px;
        &,
        * {
            ${type.heading2}
            color: white;
        }
    }

    ${Description} p:not(:first-child) {
        margin-top: 1em;
    }

    ${CTA} {
        ${media.phone`
            margin-top: 1em;
        `}
        a {
            ${hoverState}
        }
    }
`

// Partners

const PartnerImages = styled.div``
const PartnerImage = styled(motion.div)``
const OverlayColour = styled(motion.div)``

const PartnerItems = styled.div``
const BGOverlay = styled(motion.div)``
const Content = styled.div``
const PartnerLogoWrap = styled(motion.div)``
const PartnerLogo = styled.img`
    ${props => {
        if (props.mobileWidth)
            return css`
                ${media.phone`
                    max-width: ${props.mobileWidth}!important;  
                `}
            `
    }}
`
const Tagline = styled(motion.div)``

const Partners = styled.div`
	position: relative;

	${PartnerImages} {
        ${media.phone`
            display: none!important;
        `}
		${PartnerImage} ${BGImage}, ${OverlayColour} {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		${OverlayColour} {
			mix-blend-mode: multiply;

            ${media.phone`
                display: none;
            `}
		}
	}

	${PartnerItems} {
		display: flex;
		flex-flow: row wrap;
		position: relative;
		z-index: 1;

        ${media.phone`
            flex-direction: column;
            flex-wrap: nowrap;
            background-color: rgba(0,0,0,0.9);
        `}

		${Item} {
			position: relative;
			display: flex;
			width: 50%;
			min-height: 440px;
			padding: 64px 0;

            &:focus,
            &:active {
                outline: 0;
            }

			// reorder items
			&:nth-child(1) {
				order: 1;
			}
			&:nth-child(4) {
				order: 2;
			}
			&:nth-child(2) {
				order: 3;
			}
			&:nth-child(3) {
				order: 4;
			}

			&:first-child, &:nth-child(2) {
				justify-content: flex-end;
			}


            ${media.phone`
                min-height: initial;
                width: 100%;
                padding: 0;
                order: initial!important;

                &:first-child {
                    padding-top: 80px;
                }

                &:last-child {
                    ${PartnerLogoWrap} {
                        margin-bottom: 16px;
                    }
                }
            `}
		}

		${BGOverlay} {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.7);

            ${media.phone`
                display: none;
            `}
		}

		${Content} {
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: ${maxWidth / 2}px;
			max-width: 100%;
			${padding}
		}

		${PartnerLogoWrap} {
			display: flex;
			justify-content: center;
            margin-bottom: 48px;

            ${media.phone`
                margin-bottom: 38px;
            `}

			${PartnerLogo} {
                max-width: 100%;
            }
		}

		${Tagline} {
			text-align: center;

            ${media.phone`
                min-height: 120px;
                padding-bottom: 32px;
            `}

			&, * {
				width: 100%;
				${type.body}
			}
		}
	}
`

// Footer

const SmallText = styled.div``
const Footer = styled.div`
    position: relative;
    background-color: black;

    ${Container} {
        ${sectionContainer}

        ${Info} {
            padding: 180px 0 64px;

            ${media.phone`
                padding-top: 48px;
            `}
        }
    }

    ${Description}, ${SmallText} {
        &,
        * {
            ${type.body}
            color: white;
        }

        h3 {
            font-weight: 600;
        }

        p:not(:first-child) {
            margin-top: 1em;
        }
    }

    ${Description} a {
        ${hoverState}
    }

    ${SmallText} {
        margin-top: 2em;
    }
`

export default Index
