import { stripIndent } from "common-tags";
import { partners } from './partners'
export const data = {
    intro: {
        heading: stripIndent`
            Pavilion Partners is a network of complementary property specialists – that collaborate and represent the full spectrum of professional services.
        `,
    },
    about: {
        description: stripIndent`
            Whether it’s understanding, communicating, designing or engaging – our shared ethos places people at the heart of everything we do. As trusted and frequent collaborators, we create efficiencies while delivering solutions beyond expectation that add social, cultural, environmental and economic value to your places.</br></br>
        `,
    },
    partners: {
        items: partners
    },
    footer: {
        contactInfo: stripIndent`
            <h3>General Enquiries</h3>
            <p>
                <a href="tel:+61296993113">+61 2 9699 3113</a><br>
                <a href="mailto:info@pavilion.partners">info@pavilion.partners</a>
            </p>

        `,
        copyright: stripIndent`
            <p>&copy; Pavilion Partners. All rights reserved.</p>
        `
    }
}